.ExtraSymptom {
  text-align: left;
}

.extraSymptomWrapper {
  display: flex;
}

.extraSymptomChild {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  width: 30%;
  max-width: 30%;
}

.extraSymptomChild2 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;
  width: 70%;
  max-width: 70%;
  border-left: 4px solid #0b2d5b;
}

.scrollbar {
  height: 350px;
  overflow: scroll;
}

.symptomsList {
  text-align: left;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

.button-group {
  display: flex;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
}

.button-group-questions {
  display: flex;
  justify-content: flex-end;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  max-width: 200px;
}

.button-group .button-group-item {
  flex-grow: 1;
}

.button-group-item {
  padding: 12px 16px;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: #0b2d5b;
  font-family: Graphik, -apple-system, system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 1.15;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  text-align: center;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
  transition: all 500ms ease-in-out;
  box-sizing: border-box; /* include padding and border in width calculation */
}

.buttonGroupItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.button-group-item:last-child {
  border-right: none;
}

.button-group-item:hover {
  background-color: #0a46e4;
  border-color: #0a46e4;
}

.active {
  background-color: #0a46e4;
  border-color: #0a46e4;
}

/* CSS */
.button-26 {
  appearance: button;
  background-color: #0b2d5b;
  border-radius: 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Graphik, -apple-system, system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 1.15;
  overflow: visible;
  padding: 12px 16px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: all 500ms ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 100%;
}

.button-26:disabled {
  opacity: 0.5;
}

.button-26:focus {
  outline: 0;
}

.button-26:hover {
  background-color: #0a46e4;
  border-color: #0a46e4;
}

.button-26:active {
  background-color: #0039d7;
  border-color: #0039d7;
}

.selectBox {
  float: right;
}

* {
  outline: none;
}

.select-box select {
  background: transparent;
  width: 200px;
  height: 35px;
  padding: 5px;
  font-size: 16px;
  line-height: 1;
  border: 0;
  border-radius: 0;
  -webkit-appearance: none;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-box {
  float: left;
  width: 180px;
  height: 35px;
  overflow: hidden;
  background: url(https://dl.dropbox.com/u/122540013/img/arrow.png) no-repeat right #fff;
  border: 1px solid #ddd;
  border-radius: 3px 5px 5px 3px;
}

.select-box select {
  padding-right: 0;
  vertical-align: top !important;
  text-align: left;
  height: auto;
  line-height: 1em;
}

.extraSymptomTitle {
  font-weight: 500;
  text-align: left;
}

.answeredQuestion {
  float: left;
  white-space: nowrap;
  margin-right: 1rem;
}

.question {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: left;
}

.questionMark {
}
