Footer {
  display: flex;
  justify-content: space-between;
  margin-left: 9.5em;
  margin-right: 9.5em;

}

p {
  font-size: 80%;
  font-weight: 600;
  color: #0b2d5b;
}
