.startOver-container {
  text-align: center;
}

.startOver-buttonsWrapper {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.button-26 {
  appearance: button;
  background-color: #0b2d5b;
  border: 0px solid #1652f0;
  border-radius: 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Graphik, -apple-system, system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 1.15;
  overflow: visible;
  padding: 12px 16px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: all 500ms ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 200px !important;
}

.button-26:disabled {
  opacity: 0.5;
}

.button-26:focus {
  outline: 0;
}

.button-26:hover {
  background-color: #0a46e4;
  border-color: #0a46e4;
}

.button-26:active {
  background-color: #0039d7;
  border-color: #0039d7;
}

.startOver-contentWrapper {
  border: 0.1rem solid #dadada;
  border-radius: 0.3rem;
}

.startOver-header {
  font-size: 1.75rem;
  font-weight: bold;
  margin-top: 2rem;
}

@media (min-width: 100px) {
  .startOver-buttonsWrapper {
    margin: 2rem;
  }
}

@media (min-width: 900px) {
  .startOver-buttonsWrapper {
    margin: 0rem;
  }
}
