a,
a label {
  cursor: pointer;
}

.Summary {
  text-align: left;
}

.summaryWrapper {
}

.summaryChild {
  gap: 0.5rem;
  padding: 1rem;
  flex-wrap: nowrap;
  justify-content: space-around;
  border-bottom: 1px solid #e0e0e0;
}

@media (min-width: 100px) {
  .summary-buttonsWrapper {
    margin: 2rem;
  }
}

@media (min-width: 900px) {
  .summary-buttonsWrapper {
    margin: 0rem;
  }
}

.summary-buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 0.3rem;
}

.summary-footer {
  height: 2rem;
}

.summary-buttonGroup:active {
  transform: translateY(1px);
}

.summary-buttonGroup:hover {
  opacity: 90%;
}

.summary-buttonGroup {
  position: relative;
  width: 12rem;
  margin: 1rem;
  margin-bottom: 2rem;
}

.summary-button {
  margin-top: 3rem;
  background-color: #0b2d5b;
  color: white;
  width: 100%;
  height: 8rem;
  border-radius: 0.3rem;
  font-size: 2em;
  font-weight: bold;
}

.summary-halfCircle {
  position: absolute;
  left: 25%;
  top: 1rem;
  height: 30%;
  width: 50%;
  background-color: #0b2d5b;
  border-radius: 10rem 10rem 0 0;
  display: inline-block;
}

.summary-label {
  color: #0b2d5b;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  font-size: 2em;
  font-weight: bold;
}

.summary-halfCircle img {
  width: 50%;
  margin-top: 1rem;
}

.title-area {
  font-weight: bold;
  width: 100%;
  text-align: left;
}

.description-area {
  width: 100%;
  text-align: left;
  color: lightslategrey;
  height: 50px;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

.description-area.expanded {
  height: auto;
}

.description-area.collapsed {
  height: 50px;
}

.footer-area {
  width: 100%;
  text-align: left;
  min-height: 40px;
  position: relative;
}

.footer-area button {
  float: right;
  margin-left: 1em;
}

.progress-bar {
  position: absolute;
  bottom: 0;
}

#title {
}

/* CSS */
.button-summary {
  appearance: button;
  background-color: #0b2d5b;
  border: 1px solid #1652f0;
  border-radius: 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  font-family: Graphik, -apple-system, system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  line-height: 1.15;
  overflow: visible;
  padding: 12px 16px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: all 80ms ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
}

.button-summary:disabled {
  opacity: 0.5;
}

.button-summary:focus {
  outline: 0;
}

.button-summary:hover {
  background-color: #0a46e4;
  border-color: #0a46e4;
}

.button-summary:active {
  background-color: #0039d7;
  border-color: #0039d7;
}
