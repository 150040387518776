.structure {
  display: grid;
  grid-template-columns: 10% 80%;
}

.timeline {
  border-right: 0.3rem solid #0b2d5b;
  position: relative;
}

.timeline-end {
  border-right: 0.3rem solid rgba(0, 0, 0, 0);
  position: relative;
}

.dot {
  position: absolute;
  right: -0.6rem;
  height: 0.9rem;
  width: 0.9rem;
  background-color: #0b2d5b;
  border-radius: 50%;
  display: inline-block;
}

.description {
  text-align: left;
  font-style: italic;
}

.title {
  font-weight: 600;
}

@media (min-width: 100px) {
  .grid {
    display: grid;
    grid-template-columns: 90%;
    grid-gap: 1rem;
  }
  .title {
    display: flex;
    text-align: left;
    justify-content: flex-start;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media (min-width: 900px) {
  .grid {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 1rem;
  }
}
