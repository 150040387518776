.logo-container {
  display: grid;
  grid-template-columns: 70% 30%;
  background-color: #f5f5f5;
  margin-bottom: 2rem;
}

.header {
  color: #1c1c1c;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 10%;
}
p {
  text-align: left;
}

.slogan {
  font-size: 160%;
  font-weight: 300;
}

img {
  max-width: 90%;
}
