@media (min-width: 100px) {
  .choosePet-buttonsWrapper {
    margin: 2rem;
  }
}

@media (min-width: 900px) {
  .choosePet-buttonsWrapper {
    margin: 0rem;
  }
}

.choosePet-buttonsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  border-radius: 0.3rem;
}
.choosePet-footer {
  height: 2rem;
}

.choosePet-buttonGroup:active {
  transform: translateY(1px);
}

.choosePet-buttonGroup:hover {
  opacity: 90%;
}

.choosePet-buttonGroup {
  position: relative;
  width: 12rem;
  margin: 1rem;
  margin-bottom: 2rem;
}

.choosePet-hover-effect {
  background: white;
  color: #0b2d5b;
  border: 1px solid #0b2d5b;
}

.choosePet-button {
  margin-top: 3rem;
  background-color: #0b2d5b;
  color: white;
  width: 100%;
  height: 8rem;
  border-radius: 0.3rem;
  border: 0px solid #1652f0;
  transition: all 500ms ease-in-out;
  font-size: 2em;
  font-weight: bold;
}

.choosePet-halfCircle {
  position: absolute;
  left: 25%;
  top: 1rem;
  height: 30%;
  width: 50%;
  background-color: #0b2d5b;
  border-radius: 10rem 10rem 0 0;
  display: inline-block;
}
.choosePet-label {
  color: #0b2d5b;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  font-size: 2em;
  font-weight: bold;
}
.choosePet-halfCircle img {
  width: 50%;
  margin-top: 1rem;
}
