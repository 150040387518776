@keyframes blinkSelected {
  0% {
    fill: #254872;
  }
  50% {
    fill: #2ba5fc;
  }
  100% {
    fill: #254872;
  }
}

@keyframes blinkNotSelected {
  0% {
    fill: white;
  }
  50% {
    fill: #2ba5fc;
  }
  100% {
    fill: white;
  }
}

.anatomyContentWrapper {
  display: flex;
  justify-content: space-between;
}

.skin {
  stroke: white;
  transform: translate(-0.3em,-0.3em)  
}

.anatomyChild {
  display: flex;
  flex-direction: column;
  max-width: 60%;
  gap: 0.5rem;
  padding: 1rem;
}

.button-end {
  margin-top: auto;
}

svg {
  max-width: 100%;
  height: auto;
}

.symptomsList {
  text-align: left;
}
img {
  height: auto;
}

#dog-grey {
  height: auto;
}

.highlight:hover {
  animation: blinkNotSelected 1s infinite;
  transition: background-color 1s ease;
}

.hovered,
.selectedRegion {
  fill: #254872;
  transition: background-color 1s ease;
}
.highlightedAsSelectedHowered,
.selectedRegion:hover {
  animation: blinkSelected 1s infinite;
  transition: background-color 1s ease;
}
.highlightedAsNotSelectedHowered,
.notSelectedRegion:hover {
  animation: blinkNotSelected 1s infinite;
  transition: background-color 1s ease;
}

#Head-dog:hover,
#Back-legs-dog:hover,
#Front-legs-dog:hover,
#Back-tail-dog:hover,
#Stomach-dog:hover,
#Ribcage-dog:hover,
#Skin-dog:hover,
#Head-cat:hover,
#Front-leg1-cat:hover,
#Front-leg2-cat:hover,
#Back-leg1-cat:hover,
#Back-leg2-cat:hover,
#Back-tail-cat:hover,
#Stomach-cat:hover,
#Ribcage-cat:hover,
#Skin-cat:hover
{
  cursor: pointer;
}
