.infoDialogContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  outline: none;
}

.infoDialogButtonBlock {
  width: 100%;
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.infoDialogDescriptionBlock {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.infoDialogLink {
  margin-top: 10px;
}