.symptoms-contentWrapper {
  border: 0.1rem solid #dadada;
  border-radius: 0.3rem;
  padding: 3rem;
}

.questionsContainer {
  display: flex;
  flex-direction: column;
}

@media (min-width: 100px) {
  .symptoms-contentWrapper {
    margin: 2rem;
  }
}

@media (min-width: 900px) {
  .symptoms-contentWrapper {
    margin: 0rem;
  }
}
